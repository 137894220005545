// prettier-ignore
const actionButtonStyling = action =>
  action ? `.button.extended-profile-job__action-button {
  background: ${action.background};
  color: ${action.color};
  border: 1px solid ${action.background};
}
  .button.extended-profile-job__action-button:hover {
  background: ${action.color};
  color: ${action.background};
  border: 1px solid ${action.background};
}` : `.extended-profile-job__action-button { display: none; }`;

const isWhite = (color) => color === '#FFF' || color === '#FFFFFF';

const borderStyling = (main, action, slogan) => {
    const backgroundColor = main.background;
    if (isWhite(backgroundColor) || (slogan && isWhite(slogan.background)))
        return action && !isWhite(action.background)
            ? `1px solid ${action.background}`
            : '1px solid #C3CCD9';
    return 'none';
};

module.exports = ({ action, main, slogan, extraBranding }) => `.extended-profile-job .expansion-panel {
  background: ${
      slogan && isWhite(main.background) ? slogan.background : main.background
  };
  border: ${borderStyling(main, action, slogan)}
}
.extended-profile-job .expansion-panel__clicktarget, .extended-profile-job .expansion-panel__summary {
  color: ${slogan ? slogan.color : 'none'};
  background: ${slogan ? slogan.background : main.background}
}

.extended-profile-job .expansion-panel__details {
  background-color: ${main.background}
}

.extended-profile-job__org-info > a.link {
  color: ${main.color};
  text-decoration: underline;
}
.extended-profile-job__chevron {
  fill: ${slogan ? slogan.color : 'none'}
}
.extended-profile-background {
  background-position: center;
  background-size: cover;
  padding-top: 38.27%;
  background-image: url(${extraBranding.backgroundImageUrl});
}
${actionButtonStyling(action)}`
