import pulse from '@finn-no/pulse-sdk';

const trackExternalPulse = (href, finnkode) => {
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name: 'Extended profile link',
        object: {
            id: 'extended-profile-link',
            type: 'UIElement',
            elementType: 'Link',
            hasExtendedProfile: true,
            items: [
                {
                    id: finnkode,
                    type: 'ClassifiedAd',
                    name: 'n/a',
                    category: 'n/a',
                },
            ],
        },
        target: {
            id: href,
            type: 'ExternalContent',
            name: 'Go to webpage',
            referralCommission: false,
        },
    });
};

const trackMoreAdsPulse = (href) => {
    pulse.trackEvent({
        type: 'Click',
        intent: 'Open',
        name: 'More ads from this organization',
        object: {
            id: 'more-ads-from-this-org-link',
            type: 'UIElement',
            elementType: 'Link',
            hasExtendedProfile: true,
        },
        target: {
            id: href,
            type: 'Listing',
        },
    });
};

export default {
    trackExternalPulse,
    trackMoreAdsPulse,
};
