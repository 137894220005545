import { MessageBus } from '@podium/browser';
import * as tracking from './tracking';
import template from '../template.cjs';

const { trackExternalPulse, trackMoreAdsPulse } = tracking.default;
const messageBus = new MessageBus();

const getShadowRoot = () => {
    const el = document.querySelector('jobextendedprofilepodlet-isolated');
    return el ? el.shadowRoot : undefined;
};

const setPreview = (event) => {
    const shadowRoot = getShadowRoot();
    if (!shadowRoot) return;

    const previewContainer = shadowRoot.querySelector(
        '.extended-profile__preview',
    );
    const newPreview = template(event.payload);
    previewContainer.innerHTML = newPreview;
};

const initialize = (shadowRoot) => {
    // Populate preview with initial data
    window.addEventListener('DOMContentLoaded', () => {
        const event = messageBus.peek('epPodlet', 'preview');
        if (event) setPreview(event);
    });

    messageBus.subscribe('epPodlet', 'preview', (event) => {
        setPreview(event);
    });

    const epClickTarget = shadowRoot.querySelector(
        '.extended-profile-job--compact button.expansion-panel__clicktarget',
    );
    const expPanel = shadowRoot.querySelector(
        '.extended-profile-job--compact .expansion-panel',
    );
    const ele = shadowRoot.querySelector('.extended-profile-job');
    const finnkode =
        ele && ele.hasAttribute('data-finnkode')
            ? ele.getAttribute('data-finnkode')
            : '';

    if (epClickTarget && expPanel) {
        expPanel.classList.remove('expansion-panel--open');

        let open = false;
        epClickTarget.addEventListener('click', () => {
            const chevronSvg = shadowRoot.querySelector(
                '.extended-profile-job__chevron',
            );
            const expPanelDetails = shadowRoot.querySelector(
                '.extended-profile-job--compact .expansion-panel__details',
            );

            open = !open;
            if (open) {
                chevronSvg.style.transform = 'rotate(180deg)';
                expPanel.classList.add('expansion-panel--open');
                expPanelDetails.style = 'display: block';
            } else {
                chevronSvg.style.transform = 'rotate(0deg)';
                expPanel.classList.remove('expansion-panel--open');
                expPanelDetails.style = 'display: none';
            }
        });
    }

    [...document.getElementsByClassName('extendedProfileTrack')].forEach((el) =>
        el.addEventListener('click', () => {
            trackExternalPulse(el.href, finnkode);
        }),
    );

    [...document.getElementsByClassName('moreAdsTrack')].forEach((el) =>
        el.addEventListener('click', () => {
            trackMoreAdsPulse(el.href);
        }),
    );
};

// Express Podlet version 8 and above is isolated so to get elements, we need to access the shadowRoot
const shadowRoot = getShadowRoot();
if (shadowRoot) {
    initialize(shadowRoot);
}
