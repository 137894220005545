const { html: beautifyHtml } = require('js-beautify');
const chevronSvg = require('./lib/chevron-svg.cjs');
const stylingTemplate = require('./styling-template.cjs');

module.exports = (data, finnkode, expandable) => {
    const {
        action,
        slogan,
        moreAds,
        companyProfile,
        profileLinks,
        extraBranding,
        relatedLinks,
    } = data;

    const imageScript = beautifyHtml(
        `<a class="u-display-block" href="${extraBranding.brandingLinkUrl}" 
    aria-label="${extraBranding.brandingLinkText}" 
    data-controller="trackExtendedProfileLinkExtraBranding">
        <div class="widescreen-image-holder extended-profile-background"></div>
      </a>`.replace(/\n/g, '\\n'),
    );

    return `<style type="text/css">${stylingTemplate(data)}</style>
      <script type="text/javascript">
        window.imageHolder = window.imageHolder || document.querySelector('.extended-profile-job__image-holder');

        if (window.imageHolder) {
          window.imageHolder.innerHTML = '${imageScript}';
        }
      </script>
      
      <div class="extended-profile-job ${
          expandable ? 'extended-profile-job--compact' : ''
      }" data-finnkode="${finnkode}">
        <div class="expansion-panel expansion-panel--open">
          ${
              slogan
                  ? `<button aria-expanded="true" type="button" class="expansion-panel__clicktarget">
              <div class="expansion-panel__summary">
                <div class="u-b1">${slogan.text}</div>
              </div>
              ${chevronSvg()}
            </button>`
                  : ''
          }
          <div class="expansion-panel__details">
            <div class="extended-profile-job__org-info u-pt16">
              <a
                href="${companyProfile.url}"
                class="link showCompanyTrack u-caption"
              >
                ${companyProfile.text}
              </a>
            </div>
            <div class="extended-profile-job__org-info">
              <a
                href="${moreAds.url}"
                class="link moreAdsTrack u-caption"
              >
                ${moreAds.text}
              </a>
            </div>
              ${
                  relatedLinks.length > 0
                      ? `<div class="extended-profile-job__related-links">
                ${relatedLinks
                    .map(
                        (link) =>
                            `<div class="extended-profile-job__org-info">
                    <a 
                      href="${link.url}" 
                      class="link extendedProfileTrack u-caption" 
                      target="_blank"
                      rel="noopener external nofollow"
                    >
                      ${link.text}
                    </a>
                  </div>`,
                    )
                    .join('')}
              </div>`
                      : ''
              }
              ${
                  profileLinks &&
                  profileLinks
                      .map(
                          (link) =>
                              `<div class="extended-profile-job__org-info">
                  <a 
                    href="${link.url}" 
                    class="link extendedProfileTrack u-caption" 
                    target="_blank"
                    rel="noopener external nofollow"
                  >
                    ${link.text}
                  </a>
                </div>`,
                      )
                      .join('')
              }
            ${
                action
                    ? `<a
                href="${action.url}"
                class="button extended-profile-job__action-button extendedProfileTrack max-w-none"
                target="_blank"
              >
              <div class="u-b1">${action.text}</div>
              </a>`
                    : ''
            }
            <div class="extended-profile-job__org-info">
              <a
                href="${companyProfile.url}"
                class="extendedProfileTrack"
                target="_blank"
                rel="noopener external nofollow"
              >
                <img class="extended-profile-job__branding-image" src="${
                    extraBranding.brandingImageUrl
                }" alt="Company branding"/>
              </a>
            </div>
          </div>
        </div>
      </div>`;
};
